import * as React from "react"
import ContentLoader from "react-content-loader"
import * as styles from "./LoadingKtp.module.css"

const LoadingKtp = () => {
  return (
    <>
      <div className={styles.container}>
        <ContentLoader viewBox="0 0 337 206" backgroundColor="#dfdfdf" foregroundColor="#cccccc">
          <rect x="35%" y="0" rx="4" ry="4" width="30%" height="20" />
          <rect x="0" y="30" rx="4" ry="4" width="60%" height="20" />

          <rect x="0" y="60" rx="4" ry="4" width="72%" height="105" />
          <rect x="0" y="175" rx="4" ry="4" width="50%" height="20" />

          <rect x="76%" y="40" rx="4" ry="4" width="23%" height="96" />
        </ContentLoader>
      </div>
    </>
  )
}

export default LoadingKtp
